<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded v-if="registro.gescliente">
    <v-card flat>
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>CLIENTE</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'TIPO DE PERSONA'" :descripcion="(registro.gescliente.tipo)?registro.gescliente.tipo.descripcion:''"></texto2>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'TIPO DE DOCUMENTO'" :descripcion="(registro.gescliente.documento)?registro.gescliente.documento.descripcion:''"></texto2>
          </v-col>
          <v-col cols="12" md="4">
            <texto2 :colores="colores" :titulo="'No. DE DOCUMENTO'" :descripcion="(registro.gescliente.identificacion)?registro.gescliente.identificacion:''"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <texto2 :colores="colores" :titulo="'NOMBRE COMPLETO / RAZÓN SOCIAL'" :descripcion="(registro.gescliente.nombre)?registro.gescliente.nombre:''"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'SEXO'" :descripcion="(registro.gescliente.sexo)? registro.gescliente.sexo.descripcion:''"></texto2>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'PERTENECES AL COLECTIVO LGBTIQ+'" :descripcion="(registro.gescliente.lgbtiq)?registro.gescliente.lgbtiq.descripcion:''"></texto2>
          </v-col>
          <v-col cols="12" md="4">
            <texto2 :colores="colores" :titulo="'PERTENECES A UN GRUPO DE CONDICIÓN ESPECIAL'" :descripcion="(registro.gescliente.especial)?registro.gescliente.especial.descripcion:''"></texto2>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
// import texto2 from '@/components/widgets/text2Component.vue'
export default {
  name: 'crm_lectura_clienteComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue')
  },
  props: ['colores', 'registro'],
  data: () => ({
  }),
  created () {

  },
  methods: {

  }

}
</script>
